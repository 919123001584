import { useCallback, useEffect, useState } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { useMediaQuery } from 'usehooks-ts';
import RewardsTile from '../RewardsTile/RewardsTile';
import { Icon } from '@dx-ui/osc-icon';
import { useTranslation } from 'next-i18next';
import type { SupportedLanguage } from '@dx-ui/framework-i18n';
import { getLanguageDirection } from '@dx-ui/utilities-get-language-direction';
import type { BrandPage } from '@dx-ui/gql-types';
import cx from 'classnames';

type Props = {
  ctaClassName?: string;
  language?: SupportedLanguage;
  pageType?: string;
  shouldSuppressOffers: boolean;
  view?: string;
} & BrandPage['mosaicGrid'][0];

function RewardsGrid({
  description,
  ctaClassName,
  headline,
  id,
  items,
  language,
  pageType,
  shouldSuppressOffers,
  view,
}: Props) {
  const [t] = useTranslation('common');
  const hasReducedMotion = useMediaQuery('(prefers-reduced-motion: reduce)');
  const [selected, setSelected] = useState(0);

  const dir = getLanguageDirection(language);

  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true, align: 'start', direction: dir });

  const onSelect = useCallback(() => {
    if (!emblaApi) {
      return;
    }
    const selectedItemIndex =
      emblaApi.selectedScrollSnap() < 5
        ? emblaApi.selectedScrollSnap() + 1
        : emblaApi.selectedScrollSnap();
    setSelected(selectedItemIndex);
  }, [emblaApi, setSelected]);

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    onSelect();

    emblaApi.on('select', onSelect).on('reInit', onSelect);
  }, [emblaApi, onSelect]);

  const scrollPrev = useCallback(() => {
    if (emblaApi) {
      emblaApi.scrollPrev(hasReducedMotion);
    }
  }, [emblaApi, hasReducedMotion]);

  const scrollNext = useCallback(() => {
    if (emblaApi) {
      emblaApi.scrollNext(hasReducedMotion);
    }
  }, [emblaApi, hasReducedMotion]);

  const canScrollNextMobile = emblaApi?.canScrollNext();
  const canScrollPrevMobile = emblaApi?.canScrollPrev();

  const totalItems = items?.length;
  const pageText = `${selected} ${t('of')} ${totalItems}`;
  return id ? (
    <section
      className={cx(
        'bg-bg-alt relative mb-24 min-w-full max-w-full px-2 pb-3 shadow-[0_6px_20px_0_rgba(0,0,0,0.12)] md:pb-40 lg:min-w-[978px]',
        {
          'after:absolute after:block after:bg-bg-alt after:bottom-[-27px] after:size-14 after:shadow-[14px_14px_30px_-8px_rgba(0,0,0,0.12)] after:right-[calc(50%_-_28px)] after:border-r-white after:border-b-white after:rotate-45 after:-skew-x-12 after:-skew-y-12':
            !shouldSuppressOffers,
        }
      )}
      data-testid="rewardsBlock"
    >
      <h2 className="font-headline mb-4 pt-20 text-center text-3xl md:text-4xl">{headline}</h2>
      {description && <p className="mb-14 text-center text-xl md:text-2xl">{description}</p>}
      <div className="mr-7 hidden md:block xl:ml-5 xl:mr-10">
        {items ? (
          <div className="grid min-h-[670px] grid-flow-col grid-rows-5 gap-x-4 gap-y-20 lg:min-h-[750px] xl:grid-cols-4 xl:gap-x-6 2xl:min-h-[880px] 2xl:gap-x-14">
            <div className="row-span-5 overflow-hidden xl:col-span-2">
              <RewardsTile
                {...items[0]}
                columnIndex={1}
                itemIndex={1}
                language={language}
                ctaClassName={ctaClassName}
                pageType={pageType}
                {...(view ? { id: 'mediumItem1Column1' } : {})}
              />
            </div>
            <div className="row-span-3">
              <RewardsTile
                {...items[1]}
                columnIndex={2}
                itemIndex={1}
                language={language}
                ctaClassName={ctaClassName}
                pageType={pageType}
                {...(view ? { id: 'mediumItem1Column2' } : {})}
              />
            </div>
            <div className="row-span-2 content-end pb-11">
              <RewardsTile
                {...items[2]}
                columnIndex={2}
                itemIndex={2}
                language={language}
                ctaClassName={ctaClassName}
                pageType={pageType}
                {...(view ? { id: 'mediumItem2Column2' } : {})}
              />
            </div>
            <div className="row-span-2 pt-11">
              <RewardsTile
                {...items[3]}
                columnIndex={3}
                itemIndex={1}
                language={language}
                ctaClassName={ctaClassName}
                pageType={pageType}
                {...(view ? { id: 'mediumItem1Column3' } : {})}
              />
            </div>
            <div className="row-span-3 content-end">
              <RewardsTile
                {...items[4]}
                columnIndex={3}
                itemIndex={2}
                language={language}
                ctaClassName={ctaClassName}
                pageType={pageType}
                {...(view ? { id: 'mediumItem2Column3' } : {})}
              />
            </div>
          </div>
        ) : null}
      </div>
      <div className="md:hidden">
        <div className="relative h-full">
          <div className="overflow-hidden pb-20" ref={emblaRef} aria-roledescription="carousel">
            <ul className="grid auto-cols-[100%] grid-flow-col">
              {items?.map((item, index) => (
                <li className="size-full" dir={dir} key={item.label}>
                  <RewardsTile
                    {...item}
                    id={`smallItem${index}Column${index}`}
                    language={language}
                    ctaClassName={ctaClassName}
                    pageType={pageType}
                  />
                </li>
              ))}
            </ul>
          </div>
          <div className="relative z-10 flex items-center justify-center gap-x-14 self-end">
            <button
              type="button"
              onClick={scrollPrev}
              disabled={!canScrollPrevMobile}
              className={cx({
                invisible: !canScrollPrevMobile,
              })}
              aria-label={t('more')}
            >
              <Icon name="arrow-left" className="size-10 rtl:rotate-180" />
            </button>
            <span className="sr-only">{pageText}</span>
            <button
              type="button"
              onClick={scrollNext}
              disabled={!canScrollNextMobile}
              className={cx({
                invisible: !canScrollNextMobile,
              })}
              aria-label={t('more')}
            >
              <Icon name="arrow-right" className="size-10 rtl:rotate-180" />
            </button>
          </div>
        </div>
      </div>
    </section>
  ) : null;
}

export default RewardsGrid;
