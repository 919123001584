/* eslint-disable import/no-cycle */
import { useState } from 'react';
import type { MouseEvent } from 'react';
import { Link } from '@dx-ui/osc-link';
import cx from 'classnames';
import { useMediaQuery } from 'usehooks-ts';
import { formatLabel } from '../../utils/helpers/metrics';
import { doesUrlHaveQueryParameters } from '../../utils/routeHelpers';
import { replaceLang, type SupportedLanguage } from '@dx-ui/framework-i18n';
import { useLanguage, useMetrics } from '../../hooks';
import type { BrandLink, BrandMosaicGridItem } from '@dx-ui/gql-types';

export type RewardsTileProps = {
  columnIndex?: number;
  itemIndex?: number;
  ctaClassName?: string;
  pageType?: string;
  language?: SupportedLanguage;
  id?: string;
} & BrandMosaicGridItem;

const RewardsTile = ({
  label,
  id,
  image,
  itemIndex = 0,
  columnIndex = 0,
  headline = '',
  shortDescription = '',
  link,
  ctaClassName,
  pageType,
}: RewardsTileProps) => {
  const { metrics } = useMetrics();
  const language = useLanguage();
  const [isExpanded, setIsExpanded] = useState(false);

  const isLargeAndUp = useMediaQuery('(min-width: 769px)');
  const isMedAndUp = useMediaQuery('(min-width: 650px)');

  const tileLink = link || ({} as BrandLink);

  const tileLinkEventAction = (tileLink && !!tileLink.label && formatLabel(tileLink.label)) || '';

  const tileLinkPageDetail =
    (tileLink && !!tileLink.label && `${formatLabel(tileLink.label)}initiated`) || '';

  const tileId = id || `item${itemIndex}Column${columnIndex}`;

  const trackClick = () => {
    if (metrics && label) {
      const pageDetail = formatLabel(label);
      if (pageDetail) {
        const eventAction = `${pageDetail}initiated`;
        metrics.setEventAction([{ eventAction }]);
        metrics.trackEvent(eventAction);
        metrics.setPageDetails([{ pageDetail }]);
        metrics.trackPageDetails();
      }
    }
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    // Only track if the tile will be opening
    if (!isExpanded) {
      trackClick();
    }
    setIsExpanded((prevState) => !prevState);
  };

  const getBackgroundImageUrl = () => {
    if (isMedAndUp) {
      return image?.variants?.[1]?.url;
    }
    if (isLargeAndUp) {
      return image?.variants?.[0]?.url;
    }
    return image?.variants?.[2]?.url;
  };

  return (
    <div
      className="z-2 relative size-full bg-cover bg-right-top bg-no-repeat pt-0 hover:z-10 max-[767px]:mx-auto max-[767px]:my-0 max-[767px]:h-96 max-[767px]:w-72"
      style={{ backgroundImage: `url(${getBackgroundImageUrl()})` }}
      data-index={itemIndex}
    >
      <div
        id={`${tileId}Tile`}
        className={cx(
          'absolute -bottom-8 -right-7 top-auto min-h-20 w-full overflow-hidden px-4 pb-2.5 pt-4 2xl:px-6 2xl:pt-6',
          {
            'bg-bg-inverse': pageType,
            'bg-bg-inverse/90': pageType && isExpanded,
            'hover:bg-bg-inverse/90': pageType && !isExpanded,
            'md:min-w-56 md:w-2/4 md:right-0 lg:right-9 md:bottom-11': columnIndex === 1,
            'md:top-auto': columnIndex === 1 || columnIndex === 2,
            'md:-right- md:-bottom-11': columnIndex === 2,
            'md:bottom-auto md:-top-11': columnIndex === 3,
          }
        )}
        data-testid={`${tileId}RewardBlock`}
      >
        <h3 className="heading-lg xl:heading-xl 2xl:heading-2xl text-text-inverse pe-6 font-sans">
          <label>
            {label}
            <button
              type="button"
              className={cx(
                'bg-bg-inverse before:border-t-bg after:border-l-bg absolute bottom-2.5 z-[2] size-11 border-0 before:absolute before:left-[calc(50%_-_9px)] before:top-2/4 before:border-t before:bg-transparent before:pb-px before:pl-0 before:pr-5 before:pt-0 before:transition-transform after:absolute after:left-[calc(50%_+_1px)] after:top-[calc(50%_-_9px)] after:rotate-0 after:border-l after:bg-transparent after:pb-5 after:pl-0 after:pr-px after:pt-0 after:transition-transform ltr:right-2.5 rtl:left-2.5',
                { 'after:rotate-90': isExpanded }
              )}
              aria-label={label || undefined}
              onClick={handleClick}
              aria-expanded={isExpanded}
              aria-controls={`${tileId}Tile`}
              data-testid={`${tileId}Button`}
            />
          </label>
        </h3>
        {headline ? (
          <p
            className={cx('mx-0 mb-6 mt-2 pr-7 font-sans text-base font-bold xl:text-xl', {
              'text-bg': pageType,
              'pr-0': isExpanded,
            })}
          >
            {headline}
          </p>
        ) : null}
        <div
          className={cx(
            'flex flex-col justify-between overflow-hidden transition-[max-height] duration-200 ease-linear',
            {
              'max-h-72': isExpanded,
              'max-h-0': !isExpanded,
            }
          )}
          aria-expanded={isExpanded}
          aria-hidden={!isExpanded}
          data-testid={`${tileId}Section`}
        >
          <p
            className={cx('pb-6 font-sans text-base font-normal md:text-sm 2xl:text-base', {
              'text-text-inverse': pageType,
            })}
          >
            {shortDescription}
          </p>
          {tileLink?.url ? (
            <Link
              url={replaceLang(
                tileLink?.campaignId
                  ? tileLink.url
                  : doesUrlHaveQueryParameters(tileLink.url)
                  ? `${tileLink.url}&eventAction=${tileLinkEventAction}&pageDetail=${tileLinkPageDetail}`
                  : `${tileLink.url}?eventAction=${tileLinkEventAction}&pageDetail=${tileLinkPageDetail}`,
                language
              )}
              tabIndex={!isExpanded ? -1 : 0}
              className={cx(
                'btn btn-xl md:btn-xl xl:btn-2xl max-w-full whitespace-pre-wrap break-words text-center',
                {
                  'btn-tertiary-outline': !ctaClassName,
                  [ctaClassName as string]: !!ctaClassName,
                }
              )}
              target={tileLink.isNewWindow ? '_blank' : undefined}
              cidParams={{
                campaignId: tileLink?.campaignId as string,
                componentName: 'RewardsTile',
              }}
            >
              {tileLink.label}
            </Link>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default RewardsTile;
