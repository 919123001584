import type * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { useMediaQuery } from 'usehooks-ts';
import { Link } from '@dx-ui/osc-link';
import { Icon } from '@dx-ui/osc-icon';
import Image from 'next/image';
import cx from 'classnames';
import { icons } from '../../utils/constants/home';
import { formatLabel } from '../../utils/helpers/metrics';
import type { BrandRotatingTiles, BrandRotatingTilesItem } from '@dx-ui/queries-dx-guests-ui';
import { useTranslation } from 'next-i18next';
import { useMetrics } from '../../hooks';
import { getLanguageDirection } from '@dx-ui/utilities-get-language-direction';

export function assetPath(filename: string) {
  return `/dx_guests_app/images/${filename}`;
}

const DEFAULT_SLIDES_IN_VIEW = [0, 1, 4];

interface SlideProps extends BrandRotatingTilesItem {
  dir?: string;
  iconId: number;
  id: number;
  isActive: boolean;
  isInView: boolean;
  language: string;
  onClick?: () => void;
}

const Slide = ({
  headline,
  iconId,
  id,
  isActive,
  isInView,
  language,
  link,
  shortDescription,
  dir,
}: SlideProps) => {
  const { metrics } = useMetrics();

  const handleOnClick = () => {
    if (metrics && headline) {
      const pageDetail = formatLabel(headline);
      if (pageDetail) {
        const eventAction = `${pageDetail}initiated`;
        metrics.setEventAction([{ eventAction }]);
        metrics.trackEvent(eventAction);
        metrics.setPageDetails([{ pageDetail }]);
        metrics.trackPageDetails();
      }
    }
  };

  const icon = icons[iconId];
  return (
    <li className="group relative z-[1] sm:px-5" dir={dir}>
      <div
        className={cx(
          'text-text relative z-[1] flex flex-col items-center text-center',
          'before:bg-border-alt lg:before:bg-bg-alt before:absolute before:-left-5 before:top-10 before:block before:h-px before:opacity-70 lg:group-first:before:hidden',
          'after:bg-border-alt lg:after:bg-bg-alt after:absolute after:-right-5 after:top-10 after:block after:h-px after:opacity-70 lg:group-last:after:hidden',
          {
            'before:w-[calc(50%_-_44px)]': !isActive,
            'before:w-[calc(50%_-_44px)] sm:before:w-[calc(50%_-_56px)]': isActive,
            'after:w-[calc(50%_-_44px)]': !isActive,
            'after:w-[calc(50%_-_44px)] sm:after:w-[calc(50%_-_56px)]': isActive,
          }
        )}
      >
        <div
          className={cx(
            'flex size-20 cursor-pointer items-center justify-center rounded-full shadow-[0_2px_10px_0_rgba(0,0,0,0.11)] transition-colors duration-500',
            {
              'bg-accent scale-125': isActive,
              'bg-primary-alt': !isActive,
            }
          )}
        >
          {icon ? (
            <Image
              alt={headline || ''}
              src={assetPath(icon)}
              height={100}
              width={100}
              className={cx('size-14', {
                'translate-x-1': icon?.includes('phone'),
                'h-16': icon?.includes('price'),
              })}
            />
          ) : null}
        </div>
        <div
          // eslint-disable-next-line tailwindcss/enforces-negative-arbitrary-values
          className={cx(
            'group/card border-bg border-3 focus-within:border-primary hover:border-primary lg:focus-within:border-accent lg:hover:border-accent bg-bg z-0 mt-8 min-h-48 w-80 px-8 py-12 opacity-0 shadow-[0_2px_20px_0_rgba(0,0,0,0.07)] transition-opacity duration-300 will-change-[min-height] before:absolute sm:relative sm:w-full sm:px-5 sm:py-8 sm:opacity-100 md:min-h-64 lg:border-4 ltr:text-start rtl:text-end',
            'before:border-b-bg before:-top-5 before:left-2/4 before:-translate-x-1/2 before:border-x-[20px] before:border-b-[20px] before:border-x-transparent',
            'after:border-b-bg hover:after:border-b-primary focus-within:after:border-b-primary lg:hover:after:border-b-accent lg:focus-within:after:border-b-accent after:absolute after:-top-6 after:left-2/4 after:-z-[1] after:-translate-x-1/2 after:border-x-[22px] after:border-b-[22px] after:border-x-transparent',
            {
              'md:min-h-72 before:border-b-[30px] before:border-x-[30px] after:border-b-[32px] after:border-x-[32px] after:-z-10 before:top-[85px] after:top-[80px] after:border-b-bg opacity-100 sm:before:-top-7 sm:after:-top-8':
                isActive,
              'pointer-events-auto cursor-pointer': isInView,
            }
          )}
        >
          <p className="mb-2.5 text-xl font-bold leading-5 tracking-tight xl:mb-5">{headline}</p>
          <p className="relative mb-4 text-sm last:mb-0">{shortDescription}</p>
          {link && link.label && link.url && (
            <Link
              url={link.url.replace('__LANG__', language || 'en')}
              tabIndex={!isInView ? -1 : 0}
              data-testid={`perksContentLink${id}`}
              isNewWindow={Boolean(link.isNewWindow)}
              {...(link.adaDescription ? { ['aria-label']: link.adaDescription } : {})}
              cidParams={{
                campaignId: link?.campaignId as string,
                componentName: 'RotatingTiles',
              }}
              className="text-primary hover:text-primary-alt pointer-events-auto break-words no-underline"
              anchorClassName={cx({
                'block text-sm after:absolute group-hover/card:after:block group-focus/card:after:block after:top-0 after:bottom-0 after:left-0 after:right-0':
                  isInView,
              })}
              onClick={() => handleOnClick}
            >
              {link.label}
            </Link>
          )}
        </div>
      </div>
    </li>
  );
};

interface RotatingTilesProps extends BrandRotatingTiles {
  language?: string;
}

const RotatingTiles: React.FC<React.PropsWithChildren<RotatingTilesProps>> = ({
  headline,
  description,
  items,
  language,
}) => {
  const { t } = useTranslation('translation');
  const hasReducedMotion = useMediaQuery('(prefers-reduced-motion: reduce)');
  const isLargeScreen = useMediaQuery('(min-width: 1024px)');
  const [hasLoaded, setHasLoaded] = useState(false);
  const [slidesInView, setSlidesInView] = useState(DEFAULT_SLIDES_IN_VIEW);

  const dir = getLanguageDirection(language);

  const [emblaMainRef, emblaMainApi] = useEmblaCarousel({
    align: 'center',
    loop: true,
    inViewThreshold: 0.7,
    watchDrag: false,
    watchFocus: false,
    direction: dir,
  });

  const [activeSlide, setActiveSlide] = useState(2);

  const itemsWithId = items.map((item, index) => {
    const id = index;
    return { ...item, id };
  });

  const smallScreenItemOrder = itemsWithId.slice(2).concat(itemsWithId.slice(0, 2));

  const onSelect = useCallback(() => {
    if (!emblaMainApi) {
      return;
    }
    setActiveSlide(emblaMainApi.selectedScrollSnap());
  }, [emblaMainApi, setActiveSlide]);

  useEffect(() => {
    if (!emblaMainApi) {
      return;
    }

    setSlidesInView(emblaMainApi?.slidesInView());

    emblaMainApi.slideNodes().forEach((slideNode) => {
      slideNode.addEventListener(
        'focus',
        () => {
          emblaMainApi.rootNode().scrollLeft = 0;
        },
        {
          passive: true,
          capture: true,
        }
      );
    });

    setHasLoaded(true);

    onSelect();

    emblaMainApi.on('select', onSelect).on('reInit', onSelect);
  }, [emblaMainApi, onSelect, slidesInView, setSlidesInView]);

  const scrollPrev = useCallback(() => {
    if (emblaMainApi) {
      emblaMainApi.scrollPrev(hasReducedMotion);
    }
  }, [emblaMainApi, hasReducedMotion]);

  const scrollNext = useCallback(() => {
    if (emblaMainApi) {
      emblaMainApi.scrollNext(hasReducedMotion);
    }
  }, [emblaMainApi, hasReducedMotion]);

  const pagerText = () => {
    const current = itemsWithId[activeSlide] ? itemsWithId[activeSlide].id + 1 : 1;
    const total = items.length.toString();
    return `${current} ${t('rotatingTiles.of')} ${total}`;
  };

  return (
    <section
      style={{
        backgroundImage:
          isLargeScreen && hasLoaded
            ? 'url(/dx_guests_app/images/banner/banner-home-perks-desktop.png)'
            : '',
      }}
      className={cx(
        'lg:bg-primary relative z-[2] bg-cover bg-[-15.75rem_-9rem] bg-no-repeat px-0 pb-4 pt-8 text-center sm:bg-[-10.75rem_-9rem] md:bg-[center_-19.5rem] lg:shadow-[0_0_7px_0_rgba(0,0,0.33)] min-[1920px]:pb-8 min-[1920px]:pt-16',
        'lg:after:bg-primary lg:after:absolute lg:after:-bottom-6 lg:after:right-[calc(50%-30px)] lg:after:block lg:after:size-14 lg:after:rotate-45 lg:after:-skew-x-12 lg:after:-skew-y-12 lg:after:shadow-[5px_5px_6px_-2px_rgba(0,0,0,0.2)]'
      )}
      data-testid="rotatingTiles"
    >
      <div className="mx-auto mb-12 mt-0 w-11/12 max-w-7xl">
        <h2 className="text-text lg:text-text-inverse font-headline heading-4xl mb-2.5 tracking-wide">
          {headline}
        </h2>
        <h3 className="text-text lg:text-text-inverse heading-xl md:heading-2xl font-sans font-normal tracking-tight">
          {description}
        </h3>
      </div>
      <section className="relative mx-auto my-0 pt-6 lg:w-[95%]">
        {/* the cards are displayed in a carousel on smaller screens */}
        <div
          className="min-h-[460px] overflow-hidden pb-16 pt-3 lg:hidden"
          ref={emblaMainRef}
          role={!isLargeScreen ? 'region' : undefined}
          aria-roledescription={!isLargeScreen ? 'carousel' : undefined}
        >
          <ul
            className="pointer-events-none grid auto-cols-[45%] grid-flow-col sm:auto-cols-[33%] lg:auto-cols-[20%]"
            aria-live="polite"
            aria-atomic="true"
          >
            {smallScreenItemOrder.map(({ headline, link, shortDescription, id }, index) => (
              // @ts-expect-error will reach out to the NHCBP team about this type error with segmentIds missing in type
              <Slide
                // eslint-disable-next-line react/no-array-index-key
                key={`${headline}-${index}`}
                id={index + 1}
                headline={headline || ''}
                link={link || undefined}
                shortDescription={shortDescription || ''}
                iconId={id}
                isActive={activeSlide === index}
                isInView={slidesInView?.includes(index)}
                language={language || 'en'}
                dir={dir}
              />
            ))}
          </ul>
        </div>
        <div
          role={!isLargeScreen ? 'group' : undefined}
          className="flex items-center justify-center lg:hidden"
        >
          <button
            id="perksPrevArrow"
            type="button"
            aria-label={t('rotatingTiles.prev')}
            onClick={scrollPrev}
          >
            <Icon name="arrowhead-left" size="2xl" />
          </button>
          <span
            className="mx-7 my-0 inline-block text-sm"
            aria-hidden="true"
            data-testid="pagerText"
          >
            {pagerText()}
          </span>
          <span aria-live="polite" aria-atomic="true" className="sr-only">
            {pagerText()}
            {t('rotatingTiles.benefits')}
          </span>
          <button
            id="perksNextArrow"
            type="button"
            aria-label={t('rotatingTiles.next')}
            onClick={scrollNext}
          >
            <Icon name="arrowhead-right" size="2xl" />
          </button>
        </div>
        {/* on larger screens the cards are not shown within the carousel per accessibility recommendation */}
        <div className="hidden min-h-[460px] overflow-hidden pb-16 pt-3 lg:block">
          <ul className="pointer-events-none grid auto-cols-[46%] grid-flow-col lg:auto-cols-[20%]">
            {itemsWithId.map(({ headline, link, shortDescription, id }, index) => (
              // @ts-expect-error will reach out to the NHCBP team about this type error with segmentIds missing in type
              <Slide
                // eslint-disable-next-line react/no-array-index-key
                key={`${headline}-${index}`}
                id={index + 1}
                headline={headline || ''}
                link={link || undefined}
                shortDescription={shortDescription || ''}
                iconId={id}
                isActive={index === 2}
                isInView
                language={language || 'en'}
              />
            ))}
          </ul>
        </div>
      </section>
    </section>
  );
};

export default RotatingTiles;
