import { useState, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { Link } from '@dx-ui/osc-link';
import { useLanguage } from '../../hooks';

type Props = {
  elementId: string;
};

const getThreshold = (elementId: string) => {
  const element = document.getElementById(elementId);
  const bounds = element && element.getBoundingClientRect();
  return bounds && bounds.bottom;
};

export const JoinAndFindHotel = (props: Props) => {
  const [t] = useTranslation('honors-home');
  const language = useLanguage();
  const { elementId } = props;
  const [isSticky, setIsSticky] = useState(false);

  const scrollHandler = () => {
    const threshold = getThreshold(elementId);
    threshold && setIsSticky(threshold <= 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  });

  if (!isSticky) {
    return null;
  }

  const joinHonorsLink = `/${language}/hilton-honors/join/?ocode=OHWBW`;

  const JoinButton = (
    <Link
      url={`${joinHonorsLink}&eventAction=joinhiltonInitiate&pageDetail=${t('joinHiltonHonors')}`}
      underline={false}
    >
      <span className="btn btn-2xl btn-primary bg-highlight border-highlight text-text-inverse mr-1/24 fixed right-0 z-50 h-auto w-11/12 whitespace-normal text-center sm:whitespace-nowrap md:top-32 md:w-auto lg:px-3 xl:top-20">
        {t('joinHiltonHonors')}
      </span>
    </Link>
  );

  return (
    <>
      <div className="hidden md:block">{JoinButton}</div>
      <div className="bg-text-inverse fixed bottom-0 left-0 z-50 flex h-20 w-full items-center justify-center shadow md:hidden">
        <span className="fixed bottom-16">{JoinButton}</span>
      </div>
    </>
  );
};
